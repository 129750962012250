<template>
  <v-app>
    <v-app-bar app color="var(--medium)" dark elevate-on-scroll>
      <div class="d-flex align-center">
        <router-link to="/">
          <v-img
            class="shrink mr-2"
            contain
            :src="require('@/assets/yonder-logo-y-white.svg')"
            transition="scale-transition"
            width="40"
          />
        </router-link>
      </div>

      <v-spacer />

      <v-app-bar-nav-icon
        class="d-sm-none d-block"
        @click.stop="drawer = !drawer"
      />
      <v-navigation-drawer
        v-model="drawer"
        absolute
        temporary
        right
        height="100vh"
      >
        <v-list nav>
          <v-list-item to="/">
            <v-list-item-title>What We Do</v-list-item-title>
          </v-list-item>
          <v-list-group no-action>
            <template v-slot:activator>
              <v-list-item-content>
                <v-list-item-title>Subteams</v-list-item-title>
              </v-list-item-content>
            </template>
            <v-list-item to="/mechanical">
              <v-list-item-icon>
                <v-icon>mdi-cog</v-icon>
              </v-list-item-icon>
              <v-list-item-title>Mechanical</v-list-item-title>
            </v-list-item>
            <v-list-item to="/software">
              <v-list-item-icon>
                <v-icon>mdi-code-braces-box</v-icon>
              </v-list-item-icon>
              <v-list-item-title>Software</v-list-item-title>
            </v-list-item>
            <v-list-item to="/electrical">
              <v-list-item-icon>
                <v-icon>mdi-lightning-bolt</v-icon>
              </v-list-item-icon>
              <v-list-item-title>Electrical</v-list-item-title>
            </v-list-item>
            <v-list-item to="/science">
              <v-list-item-icon>
                <v-icon>mdi-beaker</v-icon>
              </v-list-item-icon>
              <v-list-item-title>Science</v-list-item-title>
            </v-list-item>
          </v-list-group>

          <v-list-item to="/members">
            <v-list-item-title>Our Members</v-list-item-title>
          </v-list-item>
          <v-list-item to="/donate">
            <v-list-item-title>Donate</v-list-item-title>
          </v-list-item>

          <v-list-item to="/donate">
            <v-list-item-title>shouldlinktodonate</v-list-item-title>
          </v-list-item>

        </v-list>
      </v-navigation-drawer>

      <v-toolbar-items class="d-sm-block d-none">
        <v-btn href="/#/" text>
          <span class="link">What we do</span>
        </v-btn>

        <v-menu open-on-hover>
          <template v-slot:activator="{ on, attrs }">
            <v-btn text v-bind="attrs" v-on="on">
              <span class="link">Subteams</span>
            </v-btn>
          </template>
          <v-list>
            <v-list-item to="/mechanical">
              <v-list-item-icon>
                <v-icon>mdi-cog</v-icon>
              </v-list-item-icon>
              <v-list-item-title>Mechanical</v-list-item-title>
            </v-list-item>
            <v-list-item to="/software">
              <v-list-item-icon>
                <v-icon>mdi-code-braces-box</v-icon>
              </v-list-item-icon>
              <v-list-item-title>Software</v-list-item-title>
            </v-list-item>
            <v-list-item to="/electrical">
              <v-list-item-icon>
                <v-icon>mdi-lightning-bolt</v-icon>
              </v-list-item-icon>
              <v-list-item-title>Electrical</v-list-item-title>
            </v-list-item>
            <v-list-item to="/science">
              <v-list-item-icon>
                <v-icon>mdi-beaker</v-icon>
              </v-list-item-icon>
              <v-list-item-title>Science</v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>

        <v-btn href="/#/members" text>
          <span class="link">Our Members</span>
        </v-btn>

        <v-btn href="/#/apply" text>
          <span class="link">Apply</span>
        </v-btn>

        <v-btn href="/#/donate" text>
          <span class="link">Donate</span>
        </v-btn>
      </v-toolbar-items>
    </v-app-bar>
    <v-main>
      <router-view />
    </v-main>

    <Footer />
  </v-app>
</template>

<script>
import Footer from '@/components/Footer.vue';

export default {
  name: 'App',
  components: {
    Footer,
  },
  data() {
    return {
      drawer: null,
    };
  },
};
</script>

<style>
@import url('https://fonts.googleapis.com/css?family=Comfortaa|Source+Sans+Pro|Bungee+Shade&display=swap');

:root {
  --dark: #110e19;
  --medium: #1f1a2e;
  --light: #2d2542;
  --gray: #f7f7f7;
}
h1,
h2 {
  font-family: 'Comfortaa', cursive;
}
h1,
h2,
h3,
h4,
h5 {
  font-weight: normal;
}
h1 {
  font-size: 40px;
}
h2 {
  font-size: 30px;
}
p {
  font-size: 24px;
  font-family: 'Source Sans Pro', sans-serif;
}

ul {
  font-size: 24px;
  font-family: 'Source Sans Pro', sans-serif;
}

@media only screen and (max-width: 574px) {
  .mobile-centered {
    width: 100%;
    float: none !important;
    text-align: center;
  }
}
</style>

<style scoped>
.link {
  font-size: 18px;
  font-family: 'Comfortaa', cursive;
}
</style>
